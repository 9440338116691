<template>
  <section id="portfolio-cost-chart">
    <b-card no-body>
      <b-card-header class="align-items-baseline">
        <div>
          <b-card-title class="mb-25">Стоимость портфеля</b-card-title>
        </div>
        <div>
          <b-dropdown
            :text="period.name"
            variant="transparent"
            class="chart-dropdown"
            right
            no-caret
            toggle-class="p-0"
            size="sm"
          >
            <b-dropdown-item v-for="per in periodList" :key="per.value" @click="period = per">
              {{ per.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-card-header>

      <b-overlay :show="loadingChart" class="mb-3">
        <v-chart class="chart" :option="option" autoresize v-if="showChart" />
        <h5 v-else class="m-auto pb-1">Отсутствуют данные для постороения графика</h5>
      </b-overlay>
    </b-card>
  </section>
</template>

<script>
import { ref, watch, onMounted } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import usePortfolio from "@/comp-functions/usePortfolio";
import { historicalPortfolioCost } from "@/api/investments";
import { currencyFormatter } from "@/utils/formatter";

export default {
  name: "PortfolioCostChart",
  mounted() {
    // load items
  },
  setup() {
    const toast = useToast();
    const { currentPortfolio, updateData, currentCurrency } = usePortfolio();

    // Chart period
    const periodList = [
      { value: "1w", name: "7д" },
      { value: "1m", name: "1м" },
      { value: "3m", name: "3м" },
      { value: "6m", name: "6м" },
      { value: "ydt", name: "YDT" },
      { value: "1y", name: "1г" },
      { value: "5y", name: "5л" },
      { value: "all", name: "Все" },
      // { value: 'custom', name: 'Заданный' },
    ];
    const period = ref(periodList[5]);

    const option = ref({
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: [],
      },
      grid: {
        left: "2%",
        right: "6%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [],
      },
      yAxis: {
        type: "value",
      },
      series: [],
    });
    const showChart = ref(false);
    const loadingChart = ref(false);
    const loadChartData = async () => {
      try {
        loadingChart.value = true;
        const data = {
          portfolios: [currentPortfolio.value.pid],
          currency: currentCurrency.value.value,
          period: period.value.value,
          fromDate: null,
          toDate: null,
        };

        const { series, dates } = await historicalPortfolioCost(data);
        showChart.value = !!dates.length;

        option.value.xAxis.data = dates;

        option.value.legend.data = [];
        option.value.series = [];
        Object.values(series).forEach((el) => {
          // Legend
          option.value.legend.data.push(el.name);

          // Series
          option.value.series.push({
            name: el.name,
            type: "line",
            data: el.data,
            showSymbol: false,
            endLabel: {
              show: true,
              formatter(params) {
                return currencyFormatter(params.value, currentCurrency.value.value);
              },
            },
            emphasis: {
              focus: "series",
            },
          });
        });
      } catch (err) {
        console.error(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Ошибка загрузки данных",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Перезагрузите страницу",
          },
        });
        throw err;
      } finally {
        loadingChart.value = false;
      }
    };

    watch([currentPortfolio, updateData, period, currentCurrency], () => {
      // loadChartData();
    });
    // onMounted(loadChartData);

    return {
      periodList,
      period,
      showChart,
      loadingChart,
      option,
    };
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 400px;
}
</style>
