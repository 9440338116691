<template>
  <portfolio-cost-chart />
</template>

<script>
import PortfolioCostChart from '@/components/investments/analytics/PortfolioCostChart.vue';

export default {
  name: 'Analytics',
  components: {
    PortfolioCostChart,
  },
};
</script>

<style></style>
